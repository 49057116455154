import CommonColors from "./commonColors"

const componentStyles = {
  mb20: {
    marginBottom: 20,
  },
  mb5: {
    marginBottom: 5,
  },
  cardStyle: {
    marginBottom: 20,
    border: "1px solid #585555",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "10px",
  },
  selectStyle: {
    width: "100%",
    borderColor: "#DDDDDD",
    borderStyle: "solid",
    borderRadius: 10,
    marginBottom: 10,
    background: "rgba(51, 72, 93, 0.1)",
    border: "1px solid #000000",
    height: "auto",
  },
  noStyling: {
    textDecoration: "none",
    color: "#585555",
  },

  activityTitle: { color: "gray" },
  inputStyle: {
    background: "rgba(51, 72, 93, 0.1)",
    border: "1px solid #000000",
    borderRadius: 10,
    height: "45px",
    width: "100%",
  },

  inputStyleInside: {
    background: "rgba(51, 72, 93, 0.1)",
    border: "1px solid #000000",
    borderRadius: 10,
    height: "37px",
    width: "100%",
  },
  disabledStyle: {
    background: "rgb(214 214 214)",
    cursor: "pointer",
  },
  inputStyleProgramMatrix: {
    background: "rgba(51, 72, 93, 0.1)",
    border: "1px solid #b1b1b1",
    borderRadius: 7,
    height: "35px",
    width: "100%",
  },
  titleStyle: {
    fontFamily: "Archivo Narrow",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "32px",
  },
  textAreaStyle: {
    background: "rgba(51, 72, 93, 0.1)",
    border: "1px solid #000000",
    borderRadius: 10,
    width: "100%",
    marginTop: "10px",
  },
  activitiesCount: {
    fontFamily: "Archivo Narrow",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "20px",
    lineHeight: "40px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",

    color: "#585555",
  },
  horizontalRuler: {
    height: "2px",
    background: "#000",
    marginBottom: "25px",
    marginTop: "-12px",
  },
  indicatorAlert: {
    fontFamily: "Archivo Narrow",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "18px",
    lineHeight: "28px",
    color: "#000000",
  },
  rowStart: {
    justifyContent: "flex-start",
  },
  colCenterStart: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  colCenter: { display: "flex", justifyContent: "center" },
  colorBlack: {
    color: "black",
  },
  flexEnd: {
    display: "flex",
    justifyContent: "space-between",
  },
  selectStyleSystemAdmins: {
    width: "100%",
    borderWidth: 1,
    borderColor: "#bbbbbb",
    borderStyle: "solid",
    borderRadius: 10,
    marginRight: 20,
  },

  inputTitleAttachment: {
    width: "90%",
    borderRadius: "10px",
  },
  inputLinkAttachment: {
    width: "90%",
    borderRadius: "10px",
  },
  addAttachmentButton: {
    backgroundColor: "#e7a921",
    borderRadius: "8px",
    borderColor: "#e7a921",
    fontWeight: "600",
    color: "White",
    width: "25%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  editIcon: {
    color: "#3fa4a5",
  },
  mailIcon: {
    color: "#e7a921",
  },
  deleteIcon: { color: "red" },
  activeIcon: { color: "#3fa4a5" },
  inputStyleNewMultiSelect: {
    borderColor: "rgb(187, 187, 187)",
    border: "1px solid rgb(133 133 133)",
    borderRadius: "10px",
  },
  inputStyleNew: {
    borderColor: "rgb(187, 187, 187)",
    border: "1px solid rgb(133 133 133)",
    borderRadius: "10px",
    height: "40px",
  },

  inputStyleNewDisabled: {
    borderColor: "rgb(187, 187, 187)",
    border: "1px solid rgb(133 133 133)",
    borderRadius: "10px",
    height: "40px",
    backgroundColor: "#F5F5F5",
  },
  okButton: {
    backgroundColor: "#A6192E",
    borderRadius: "8px",
    borderColor: "#A6192E",
    fontWeight: "600",
  },
  cancelButton: {
    borderRadius: "8px",
    fontWeight: "600",
  },
  searchButton: {
    width: "100%",
    borderRadius: 10,
    color: "white",
    borderWidth: 1,
    borderStyle: "solid",
    backgroundColor: "#3ea4a5",
    borderColor: "#3ea4a5",
  },
  searchEnabledButton: {
    width: "100%",
    borderRadius: 10,
    color: "black",
    borderWidth: 1,
    borderStyle: "solid",
    fontWeight: "500",
    cursor: "not-allowed",
  },
  fontSize25: {
    fontSize: "25px",
  },
  changeCursor: {
    cursor: "pointer",
  },
  exportToCSV: {
    color: "white",
    fontWeight: "700",
    borderRadius: "10px",
    fontFamily: "Archivo Narrow",
  },
  centered: {
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
  },
  minHeightLoaderDiv: {
    minHeight: "40px",
  },
  modalButtonGraphs: {
    width: "92px",
    fontSize: "17px",
    backgroundColor: "brown",
    border: "1px solid rgb(255, 255, 255)",
    borderRadius: "9px",
    color: "White",
    fontWeight: "600",
  },
  overLayToolTipStyles: {
    fontSize: "14px",
    background: "#ffffff",
    color: "#49bbbc",
    fontWeight: 600,
    borderRadius: "10px",
    border: "1px solid #00000042",
  },
  stageNameStyling: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    color: "white",
    fontSize: "16px",
    fontWeight: "600",
  },
  stageValueStyling: {
    height: 30,
    display: "flex",
    alignItems: "center",
    color: "white",
    fontSize: "16px",
    fontWeight: "600",
    minWidth: "120px",
    justifyContent: "end",
  },

  tagsStyles: {
    borderRadius: "8px",
    fontSize: "11px",
    color: CommonColors.UA_BLUE_DARK,
    backgroundColor: CommonColors.UA_GREY,
    cursor: "default",
  },
  tagsStylesforCard: {
    borderRadius: "8px",
    fontSize: "11px",
    color: CommonColors.UA_BLUE_DARK,
    backgroundColor: CommonColors.UA_GREY,
    cursor: "pointer",
  },
  tagMoreStylesforCard: {
    borderRadius: "8px",
    fontSize: "11px",
    color: "black",
    backgroundColor: "White",
    cursor: "pointer",
  },
  tagMoreStyles: {
    borderRadius: "8px",
    fontSize: "11px",
    color: "black",
    backgroundColor: "White",
    cursor: "default",
  },
  titleSizeforCard: { fontSize: "22px", cursor: "pointer" },
  headingSizeforCard: { fontSize: "14px", cursor: "pointer" },
  titleSize: { fontSize: "22px", cursor: "default" },
  headingSize: { fontSize: "14px", cursor: "default" },
}
export default componentStyles
