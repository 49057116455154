import React from "react"
import { Bar } from "react-chartjs-2"
import _ from "underscore"
import ChartDataLabels from "chartjs-plugin-datalabels"
import Chart from "chart.js/auto"
import { Tooltip } from "antd"

Chart.register(ChartDataLabels)

function BarChart({
  icon,
  iconEvent,
  title,
  labels = [],
  datasets = [],
  onClick,
  mb = "0",
  stepSize = null,
  formatPercentage = false,
  legend = false,
  graphUrlBase64 = null,
  type,
  labelX = "",
  labelY = "",
  offset = 10,
}) {
  const adultColor = "#33485D"
  const getMaxValue = () => {
    const maxValues = datasets.map((dataset) => Math.max(...dataset.data))
    const absoluteMax = Math.max(...maxValues)
    return absoluteMax + 1
  }

  const curvedDatasets = datasets.map((dataset) => ({
    ...dataset,
    backgroundColor: type === "adult" ? adultColor : dataset.backgroundColor,
    borderRadius: {
      topLeft: 10,
      topRight: 10,
      bottomLeft: 0,
      bottomRight: 0,
    },
  }))

  const data = {
    labels,
    datasets: curvedDatasets,
  }

  const options = {
    onClick: (event, element) => {
      if (element.length && onClick) {
        onClick(element[0].index)
      }
    },
    onHover: (event, chartElement) => {
      if (onClick) {
        event.native.target.style.cursor = chartElement[0]
          ? "pointer"
          : "default"
      }
    },

    plugins: {
      legend: {
        display: legend,
      },
      tooltip: {
        enabled: _.isNull(graphUrlBase64) ? true : graphUrlBase64.length > 0,
        callbacks: {
          label: (tooltipItem) => ` ${tooltipItem.dataset.label}: ${tooltipItem.formattedValue}${
            formatPercentage ? "%" : ""
          }`,
        },
      },

      datalabels: {
        display: true,
        color: "black",
        align: "end",
        anchor: "end",
        font: { size: "11" },
      },
    },

    scales: {
      x: {
        title: {
          display: true,
          text: labelX,
        },
      },
      y: {
        title: {
          display: true,
          text: labelY,
        },
        min: 0,
        max: getMaxValue() + offset,
        beginAtZero: true,
        ticks: {
          stepSize,
          callback(value) {
            return Number(value).toFixed(0)
          },
        },
      },
    },
  }

  if (title) {
    options.plugins.title = {
      display: true,
      text: title,
    }
  }

  return (
    <div className={`mb-${mb}`}>
      <Bar data={data} options={options} />
      <div
        onClick={() => {
          iconEvent()
        }}
        className="icon"
      >
        {icon && <Tooltip title="View Label Definitions">{icon}</Tooltip>}
      </div>
    </div>
  )
}

export default BarChart
