import React from "react"
import {
  Page,
  View,
  Document,
  StyleSheet,
  Font,
  Text,
  Image,
} from "@react-pdf/renderer"
import _ from "underscore"
import archivoNarrow from "../../../assets/fonts/ArchivoNarrow-Regular.ttf"
import archivoNarrowBold from "../../../assets/fonts/ArchivoNarrow-Bold.ttf"
import uaLogo from "../../../assets/images/rsp-logo.png"

Font.register({
  family: "Archivo Narrow",
  fonts: [{ src: archivoNarrow }, { src: archivoNarrowBold, fontWeight: 700 }],
})

const pdfStyles = StyleSheet.create({
  page: {
    fontFamily: "Archivo Narrow",
    paddingTop: 6,
    paddingBottom: 40,
    paddingHorizontal: 35,
  },
  competencyItem: {
    display: "flex",
    flexDirection: "row",
    paddingLeft: 25,
    fontSize: 10,
  },
  bulletPoint: {
    paddingRight: 10,
  },
  uaLogo: {
    width: 115,
    height: 64,
    marginBottom: 20,
  },
  uaLogo1: {
    width: 115,
    height: 64,
  },

  section: {
    margin: 5,
    padding: 5,
    flexGrow: 1,
  },
  header: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: 8,
    fontWeight: 700,
    minHeight: 40,
    width: "100%",

    borderWidth: 2,
    borderBottomWidth: 2,
    color: "white",
  },
  widthHeading: {
    width: "35%",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    fontWeight: 700,
    fontSize: 15,
  },
  widthDescription: {
    width: "65%",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    fontWeight: 300,
    fontSize: 11,
    paddingRight: 11,
    paddingLeft: 15,
  },
  allData: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    alignItems: "center",
  },
  studentKey: {
    width: "12%",
    fontSize: 21,
    fontWeight: 700,
    textAlign: "center",
  },
  insideData: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 10,
    paddingBottom: 10,

    width: "100%",
  },
  studentKeyData: {
    fontSize: 11,
    // paddingTop: 10,
  },
  competencies: {
    fontSize: 13,
    fontWeight: 700,
  },
  adultsSel: {
    fontSize: 11,
    paddingTop: 10,
  },
  imageContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 10,
  },
  logo: {
    width: 100,
    height: 50,
  },
  selInner: {
    borderBottom: "1px solid #e5e5e5",
  },
})

const SELScanpdf = ({
  data,
  currentschoolLogo,
  dessaCompetencies,
  aggregatedStudentItems,
  aggregatedAdultItems,
}) => {
  const getUniqueItems = (items) => _.unique(items, (item) => item.id)

  const getDescription = (name) => {
    let description = ""
    switch (name.trim().toLowerCase()) {
      case "self-awareness":
        description = "Recognizing who I am, what I need, and how I feel relative to the world around me."
        break
      case "self management":
        description = "Managing my behavior in pro social ways."
        break
      case "social awareness":
        description = "Demonstrating an awareness of the role and values of others in the greater community."
        break
      case "social management":
        description = "Interacting with others in meaningful and productive ways."
        break
      default:
        description = ""
        break
    }
    return description
  }
  const colors = ["#A6192E", "#33485D", "#E7A922", "#0CA4A5"]
  const colorsForDessa = [
    "#A6192E",
    "#A6192E",
    "#33485D",
    "#33485D",
    "#33485D",
    "#33485D",
    "#E7A922",
    "#E7A922",
  ]
  const isDessSelStandards = data === window.gon.DESS_SEL_STANDARDS
  const colorsArray = isDessSelStandards ? colorsForDessa : colors
  const filteredGroups = data.filter((group) => {
    if (dessaCompetencies.length === 0) return true
    return Object.keys(group.standards).some((key) => dessaCompetencies.includes(key))
  })
  const colorMap = data.reduce((acc, group, originalIndex) => {
    const color = colorsArray[originalIndex % colorsArray.length]
    acc[group.name] = color
    return acc
  }, {})

  return (
    <Document>
      {filteredGroups.map((group, index) => {
        const color = colorMap[group.name]
        return (
          <Page size="A4" style={pdfStyles.page} key={index}>
            <View style={pdfStyles.imageContainer} fixed>
              <Image style={pdfStyles.uaLogo} src={uaLogo} />
              {currentschoolLogo && (
                <Image style={pdfStyles.uaLogo1} src={currentschoolLogo} />
              )}
            </View>
            <View style={pdfStyles.section}>
              <View
                style={[
                  pdfStyles.header,
                  {
                    backgroundColor: color,
                    borderColor: color,
                    color: "white",
                  },
                ]}
              >
                <View
                  style={[
                    pdfStyles.widthHeading,
                    {
                      paddingLeft: 10,
                    },
                  ]}
                >
                  <Text>{group.name}</Text>
                </View>
                {data !== window.gon.DESS_SEL_STANDARDS ? (
                  <View
                    style={[
                      pdfStyles.widthDescription,
                      { backgroundColor: color, color: "white" },
                    ]}
                  >
                    <Text>{getDescription(group.name)}</Text>
                  </View>
                ) : null}
              </View>
              {Object.keys(group.standards).map((key) => {
                if (
                  dessaCompetencies.length === 0
                  || dessaCompetencies.includes(key)
                ) {
                  const studentItems = aggregatedStudentItems
                    .filter((item) => item.sel_standards[key])
                    .map((item) => ({
                      ...item,
                    }))

                  const uniqueStudentItems = getUniqueItems(studentItems)

                  const adultItems = aggregatedAdultItems
                    .filter((item) => item.sel_standards[key])
                    .map((item) => ({
                      ...item,
                    }))
                  const uniqueAdultItems = getUniqueItems(adultItems)
                  const hasCompetencies = uniqueStudentItems.length > 0
                  const hasAdultCompetencies = uniqueAdultItems.length > 0
                  return (
                    <View
                      key={`student-item-${key}`}
                      style={pdfStyles.selInner}
                    >
                      <View
                        style={[
                          pdfStyles.allData,
                          {
                            fontWeight: hasCompetencies ? "700" : "400",
                            backgroundColor:
                              hasCompetencies || hasAdultCompetencies
                                ? `rgba(${parseInt(
                                  color.slice(1, 3),
                                  16,
                                )}, ${parseInt(
                                  color.slice(3, 5),
                                  16,
                                )}, ${parseInt(color.slice(5, 7), 16)}, 0.1)`
                                : "transparent",
                          },
                        ]}
                      >
                        <View style={pdfStyles.studentKey}>
                          <Text style={{ color }}>{key}</Text>
                        </View>
                        <View style={pdfStyles.insideData}>
                          <View style={pdfStyles.studentKeyData}>
                            <Text>
                              {group.standards[key].student_indicator_language}
                            </Text>
                          </View>
                          {hasCompetencies && (
                            <View style={pdfStyles.competencies}>
                              {uniqueStudentItems.map((item, index) => (
                                <View
                                  key={index}
                                  style={pdfStyles.competencyItem}
                                >
                                  <Text style={pdfStyles.bulletPoint}>•</Text>
                                  <Text>
                                    {item.activity} ({item.category},{" "}
                                    {item.tier_of_support},{" "}
                                    {item.length_of_time},{" "}
                                    {item.grade_levels.join(", ")})
                                  </Text>
                                </View>
                              ))}
                            </View>
                          )}
                          <View
                            style={[
                              pdfStyles.adultsSel,
                              {
                                fontWeight: hasAdultCompetencies
                                  ? "700"
                                  : "400",
                              },
                            ]}
                          >
                            <Text>
                              {group.standards[key].adult_indicator_language}
                            </Text>
                            {uniqueAdultItems.some(
                              (item) => item.category === "Adult SEL",
                            ) && (
                              <View style={pdfStyles.competencies}>
                                {uniqueAdultItems.map((item, index) => (
                                  <View
                                    key={index}
                                    style={pdfStyles.competencyItem}
                                  >
                                    <Text style={pdfStyles.bulletPoint}>•</Text>
                                    <Text>
                                      {item.activity} ({item.category},{" "}
                                      {item.tier_of_support},{" "}
                                      {item.length_of_time},{" "}
                                      {item.grade_levels.join(", ")})
                                    </Text>
                                  </View>
                                ))}
                              </View>
                            )}
                          </View>
                        </View>
                      </View>
                    </View>
                  )
                }
                return null
              })}
            </View>
          </Page>
        )
        // //  }
        //   return null
      })}
    </Document>
  )
}

export default SELScanpdf
