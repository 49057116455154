import { Col, Form, Select } from "antd"
import React from "react"
import componentStyles from "../../styles"

const { Option } = Select

function FormItemSelectCell(props) {
  return (
    <Col
      xs={24}
      sm={24}
      md={props.colDimensions ? props.colDimensions.md : 8}
      lg={props.colDimensions ? props.colDimensions.lg : 8}
    >
      <Form.Item
        label={props.label}
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        name={props.name}
        rules={props.rules}
        key={props.keys}
        initialValue={props.defaultValues}
      >
        <Select
          allowClear={!!props.allowClear}
          id={props.name}
          name={props.name}
          mode={props.mode === "multiple" ? "multiple" : null}
          disabled={props.disabled}
          showSearch
          style={componentStyles.selectStyle}
          bordered={false}
          placeholder={props.placeholder}
          optionFilterProp="children"
          onChange={(key, val) => props.handleOnChangeSelect(key, val)}
          filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase())
            >= 0}
        >
          {props.isDetail
            ? props.values
              && props.values.map((value, index) => (
                <Option key={index} value={value.value} name={props.name}>
                  {value.detail}
                </Option>
              ))
            : props.values
              && props.values.map((value, index) => (
                <Option key={index} value={value} name={props.name}>
                  {value}
                </Option>
              ))}
        </Select>
      </Form.Item>
    </Col>
  )
}

export default FormItemSelectCell
