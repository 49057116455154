const inputRequired = [
  {
    whitespace: true,
    required: true,
    message: "Please enter the value",
  },
]
const inputNonRequired = [
  {
    whitespace: true,
    required: false,
    message: "Please enter the valid value",
  },
]
const selectRequired = [
  {
    whitespace: true,
    required: true,
    message: "Please select the value",
  },
]
const selectRequiredforGradelevels = [
  {
    required: true,
    message: "Please select the value",
  },
]
const checkValidEmail = [
  {
    type: "email",
    message: "Please enter a valid Email Address",
  },
  {
    whitespace: true,
    required: true,
    message: "Please enter the value",
  },
]

const selectModeMultipleProgram = [
  {
    required: true,
    message: "Please select at least one program",
    type: "array",
  },
]
const selectModeMultipleSchool = [
  {
    required: true,
    message: "Please select at least one school",
    type: "array",
  },
]

const verfyLink = [
  {
    pattern: /^(http|https|ftp|ssh|telnet|sftp):\/\/\S*$/,
    message: "Please enter a valid URL",
  },
]

export default {
  verfyLink,
  selectModeMultipleSchool,
  selectModeMultipleProgram,
  checkValidEmail,
  inputRequired,
  selectRequired,
  selectRequiredforGradelevels,
  inputNonRequired,
}
