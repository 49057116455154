import React from "react"
import {
  Page,
  View,
  Document,
  StyleSheet,
  Font,
  Image,
} from "@react-pdf/renderer"
import archivoNarrow from "../../../assets/fonts/ArchivoNarrow-Regular.ttf"
import archivoNarrowBold from "../../../assets/fonts/ArchivoNarrow-Bold.ttf"
import uaLogo from "../../../assets/images/rsp-logo.png"

Font.register({
  family: "Archivo Narrow",
  fonts: [{ src: archivoNarrow }, { src: archivoNarrowBold, fontWeight: 700 }],
})

const pdfStyles = StyleSheet.create({
  page: {
    fontFamily: "Archivo Narrow",
    paddingTop: 14,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  uaLogo: {
    width: 115,
    height: 64,
  },
  uaLogo1: {
    width: 115,
    height: 64,
  },
  imageContainers: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 30,
  },
  imageContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 30,
  },
  image: {
    maxWidth: "100%",
    height: 250,
  },
})
const SELQualitypdf = ({ graphUrls, currentschoolLogo }) => {
  const isValidImage = (src) => src && src.startsWith("data:image/png;base64,")
  const hasValidImages = (keys) => keys.some((key) => isValidImage(graphUrls[key]))

  const renderImage = (key, style) => (
    isValidImage(graphUrls[key]) && (
      <Image src={graphUrls[key]} style={style} />
    )
  )

  const pages = [
    {
      images: ["graphUrlBase64", "graphUrlBase72"],
      extraImages: ["graphUrlBase65", "graphUrlBase66"],
      logo: true,
    },
    { images: ["graphUrlBase65", "graphUrlBase66"] },
    { images: ["graphUrlBase68", "graphUrlBase69"] },
    { images: ["graphUrlBase70", "graphUrlBase71"] },
  ]

  return (
    <Document>
      {pages.map((page, index) => hasValidImages(page.images) && (
      <Page key={index} size="A4" style={pdfStyles.page}>
        {page.logo && (
        <View style={pdfStyles.imageContainers} fixed>
          <Image style={pdfStyles.uaLogo} src={uaLogo} />
          {currentschoolLogo && (
          <Image style={pdfStyles.uaLogo1} src={currentschoolLogo} />
          )}
        </View>
        )}
        <View style={pdfStyles.imageContainer}>
          {page.images.map((key) => renderImage(key, pdfStyles.largeImage))} {/* Use largeImage style */}
        </View>
        {page.extraImages && (
        <View style={pdfStyles.imageContainer}>
          {page.extraImages.map((key) => renderImage(key, pdfStyles.largeImage))} {/* Use largeImage style */}
        </View>
        )}
      </Page>
      ))}
    </Document>
  )
}
export default SELQualitypdf
