import React, { useEffect, useState } from "react"
import {
  Col, Row, Form, notification,
} from "antd"
import _ from "underscore"
import Strings from "../../commonStrings"
import rules from "../../validations"
import FormItemSelectCell from "./FormItemSelectCell"
import FormItemInputCell from "./FormItemInputCellforActivity"
import FormItemInputCellNoCard from "./FormItemInputCellNoCard"
import Loader from "../CommonComponents/Loader"

function ProgramMatrixTabs({
  category,
  routeURL,
  setCategory,
  handleOnChange,
  handleOnChangeSelect,
  form,
  item,
  activityMode,
  saveItem,
  studentPopulation,
  updateStudentsCount,
  getActivityIdFromUrl,
  setFieldsValues,
  fieldsValues,
  isButtonSubmitted,
  setIsButtonSubmitted,
  handleGradeLevelsChange,
  selectedGradeLevels,
}) {
  const urlParams = new URLSearchParams(window.location.search)
  const [myParam] = useState(urlParams.get("activityId"))
  const [api, contextHolder] = notification.useNotification()
  const [isLoadingField, setIsLoadingField] = useState(false)

  useEffect(() => {
    setFieldsValues(item)
    setFieldsValues(item)
  }, [item])
  const handleStudentCountChange = (count, updateApiEndpoint) => {
    if (updateApiEndpoint === Strings.endPointUpdateStudentsCanBeSupported) {
      setIsLoadingField(true)
    }
    if (count <= studentPopulation) {
      showSaving()
      APIClient.put(
        `/program_matrix/program_matrix_items/${getActivityIdFromUrl(
          window.location.href,
        )}/${updateApiEndpoint}`,
        { count },
        (value) => {
          setIsButtonSubmitted(false)
          setFieldsValues(value)
          setFieldsValues(value)
          hideSaving()
          setIsLoadingField(false)
        },
        (errors) => {
          console.log(errors)
        },
      )
    }
  }

  useEffect(() => {
    $("[data-toggle=\"popover\"]").each(function () {
      const $this = $(this)
      $this.popover({
        html: true,
        trigger: "hover",
        placement: "top",
        container: $this,
      })
    })
  }, [])

  useEffect(() => {
    setCategory(routeURL)
  }, [routeURL])

  const onSubmit = () => {}
  const tierOfSupport = [
    {
      detail:
        "Universal: The support is designed for all students to have access",
      value: "Universal",
    },
    {
      detail:
        "Targeted: For students who are selected or otherwise demonstrate a risk factor to gain access to the support",
      value: "Targeted",
    },
    {
      detail:
        "Tertiary: High risk students have access to individualized supports",
      value: "Tertiary",
    },
  ]

  useEffect(() => {
    if (activityMode === "edit") {
      setTimeout(() => {
        form.validateFields()
      }, 1000)
    }
  }, [window.location])

  useEffect(() => {
    if (
      activityMode === "edit"
      && fieldsValues.tier_of_support === "Universal"
    ) {
      updateStudentsCount("update_students_can_supported", studentPopulation)
      updateStudentsCount("update_students_being_supported", studentPopulation)
    }
  }, [activityMode, fieldsValues])

  useEffect(() => {
    setCategory(routeURL)
  }, [routeURL])

  useEffect(() => {
    if (item) {
      form.setFieldsValue({
        grade_levels: Array.isArray(item.grade_levels) ? item.grade_levels : [],
      })
    }
  }, [item, form])

  return (
    <Row>
      {contextHolder}

      {item && (
        <Col xs={24} sm={24} md={24} lg={24}>
          <Form layout="vertical" onFinish={onSubmit} form={form}>
            <Col xs={24} sm={24} md={14} lg={14}>
              <FormItemSelectCell
                colDimensions={{ md: 16, lg: 16 }}
                name="categories"
                keys={`dropdown:${category}`}
                defaultValues={category}
                values={window.gon.CATEGORIES}
                handleOnChangeSelect={saveItem}
              />
            </Col>
            <Col Col xs={24} sm={24} md={24} lg={24}>
              <FormItemInputCell
                colDimensions={{ md: 8, lg: 8 }}
                name="activity"
                label={Strings.activity}
                keys={`activity:${item.activity}`}
                type="text"
                defaultValues={item && item.activity}
                rules={rules.inputRequired}
                handleChange={(value) => handleOnChange({ target: { name: "activity", value } })}
              />
            </Col>
            <Col Col xs={24} sm={24} md={24} lg={24}>
              {item.category !== "Adult SEL" && (
                <FormItemSelectCell
                  colDimensions={{ md: 12, lg: 12 }}
                  name="grade_levels"
                  mode="multiple"
                  defaultValues={selectedGradeLevels}
                  values={window.gon.GRADE_LEVELS}
                  handleOnChangeSelect={handleGradeLevelsChange}
                  label={Strings.gradeLevelTitle}
                  rules={rules.selectRequiredforGradelevels}
                />
              )}
            </Col>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              className={`d-flex ${
                (item.tier_of_support === "Targeted"
                  || item.tier_of_support === "Tertiary")
                && "justify-content-between"
              }`}
            >
              <FormItemSelectCell
                colDimensions={{ md: 12, lg: 12 }}
                name="tier_of_support"
                keys={`tier_of_support:${item.tier_of_support}`}
                defaultValues={item && item.tier_of_support}
                values={tierOfSupport}
                handleOnChangeSelect={(e, val) => {
                  handleOnChangeSelect(e, val)
                  form.setFieldsValue({
                    students_being_supported: "reset",
                  })
                  form.setFieldsValue({
                    students_can_be_supported: "reset",
                  })

                  if (val.value === "Targeted" || val.value === "Tertiary") {
                    updateStudentsCount("update_students_can_supported", null)
                    updateStudentsCount(
                      "update_students_being_supported",
                      null,
                    )
                  } else if (val.value === "Universal") {
                    updateStudentsCount(
                      "update_students_can_supported",
                      studentPopulation,
                    )
                    updateStudentsCount(
                      "update_students_being_supported",
                      studentPopulation,
                    )
                  }
                }}
                rules={rules.selectRequired}
                label={Strings.support}
                isDetail
              />

              {!(fieldsValues.category === "Adult SEL")
                && item.tier_of_support
                && (item.tier_of_support === "Targeted"
                  || item.tier_of_support === "Tertiary") && (
                  <>
                    <Col xs={24} sm={24} md={5} lg={5}>
                      <div
                        key={item.students_can_supported}
                        className="studentsSupport"
                      >
                        <FormItemInputCellNoCard
                          min={1}
                          max={studentPopulation}
                          colDimensions={{ md: 5, lg: 5 }}
                          name="students_can_be_supported"
                          keys={`tier_of_support:${item.students_can_supported}`}
                          defaultValues={item.students_can_supported}
                          handleChange={(e) => {
                            handleStudentCountChange(
                              null,
                              "update_students_being_supported",
                            )
                            form.setFieldsValue({
                              students_being_supported: "reset",
                            })

                            if (
                              parseInt(e.target.value, 10) > studentPopulation
                            ) {
                              handleStudentCountChange(
                                studentPopulation,
                                "update_students_can_supported",
                              )

                              api.error({
                                message: "Could not save changes",
                                description:
                                  "students can supported should be less than student population",
                                placement: "topRight",
                                closeIcon: false,
                                className: "custom-class-error-message",
                                duration: 7,
                              })
                            } else if (parseInt(e.target.value, 10) === 0) {
                              handleStudentCountChange(
                                1,
                                "update_students_can_supported",
                              )
                            } else {
                              handleStudentCountChange(
                                e.target.value
                                  ? parseInt(e.target.value, 10)
                                  : null,
                                "update_students_can_supported",
                              )
                            }
                          }}
                          label={Strings.studentsCanBeSupported}
                        />

                        {!_.isEmpty(fieldsValues)
                          && !fieldsValues.students_can_supported
                          && isButtonSubmitted && (
                            <span style={{ color: "#ff0000b0" }}>
                              Please enter the value
                            </span>
                        )}
                      </div>
                    </Col>

                    <Col xs={24} sm={24} md={5} lg={5}>
                      <div
                        key={item.students_being_supported}
                        className="studentsSupport"
                      >
                        <FormItemInputCellNoCard
                          loading={isLoadingField}
                          disabled={isLoadingField}
                          min={1}
                          max={fieldsValues.students_can_supported}
                          type="number"
                          colDimensions={{ md: 5, lg: 5 }}
                          name="students_being_supported"
                          keys={`students_being_supported:${
                            item.students_being_supported || fieldsValues
                          }`}
                          defaultValues={item.students_being_supported}
                          handleChange={(e) => {
                            const valueSyntheticEvent = e.target.value
                              ? parseInt(e.target.value, 10)
                              : null

                            APIClient.get(
                              `/program_matrix/program_matrix_items/${myParam}`,
                              (response) => {
                                setFieldsValues(response)
                                if (response.students_can_supported === null) {
                                  api.error({
                                    message: Strings.couldNotFound,
                                    description: Strings.couldNotFoundMessage,
                                    placement: "topRight",
                                    closeIcon: false,
                                    className: "custom-class-error-message",
                                    duration: 7,
                                  })
                                  form.setFieldsValue({
                                    students_being_supported: "reset",
                                  })
                                } else if (
                                  valueSyntheticEvent
                                  > fieldsValues.students_can_supported
                                ) {
                                  handleStudentCountChange(
                                    fieldsValues.students_can_supported,
                                    "update_students_being_supported",
                                  )
                                } else {
                                  handleStudentCountChange(
                                    valueSyntheticEvent === 0
                                      ? 1
                                      : valueSyntheticEvent,
                                    "update_students_being_supported",
                                  )
                                }
                              },
                            )
                          }}
                          label={(
                            <div className="d-flex">
                              <div className="px-1">
                                {Strings.studentsAreBeingSupported}
                              </div>
                              {isLoadingField && <Loader size="15px" />}
                            </div>
                          )}
                        />
                        {!_.isEmpty(fieldsValues)
                          && !fieldsValues.students_being_supported
                          && isButtonSubmitted && (
                            <span style={{ color: "#ff0000b0" }}>
                              Please enter the value
                            </span>
                        )}
                      </div>
                    </Col>
                  </>
              )}
              {item.tier_of_support === "Universal"
                && !(fieldsValues.category === "Adult SEL") && (
                  <Col
                    xs={24}
                    sm={24}
                    md={8}
                    lg={8}
                    className="supportedColumn"
                  >
                    <div className="font-size15">
                      {`students can be supported: ${
                        _.isNull(studentPopulation)
                          ? "No data found"
                          : studentPopulation
                      }`}
                    </div>
                    <div className="font-size15">
                      {`students are being supported: ${
                        _.isNull(studentPopulation)
                          ? "No data found"
                          : studentPopulation
                      }`}
                    </div>
                  </Col>
              )}
            </Col>
            <Col Col xs={24} sm={24} md={24} lg={24}>
              <FormItemSelectCell
                colDimensions={{ md: 12, lg: 12 }}
                name="length_of_time"
                keys={`length_of_time:${item.length_of_time}`}
                defaultValues={item && item.length_of_time}
                values={window.gon.LENGTHS_OF_TIME}
                handleOnChangeSelect={handleOnChangeSelect}
                label={Strings.timeSpan}
                rules={rules.selectRequired}
              />
            </Col>

            <Col Col xs={24} sm={24} md={24} lg={24}>
              <FormItemInputCell
                colDimensions={{ md: 8, lg: 8 }}
                name="description"
                keys={`description:${item.description}`}
                label={Strings.description}
                defaultValues={item && item.description}
                type="text"
                rules={rules.inputRequired}
                value={item.description}
                handleChange={(value) => handleOnChange({ target: { name: "description", value } })}
              />
            </Col>
            <Col Col xs={24} sm={24} md={24} lg={24}>
              <FormItemInputCell
                colDimensions={{ md: 8, lg: 8 }}
                name="data_collection"
                keys={`data_collection:${item.data_collection}`}
                label={Strings.dataCollection}
                defaultValues={item && item.data_collection}
                type="text"
                rules={rules.inputNonRequired}
                value={item.data_collection}
                handleChange={(value) => handleOnChange({ target: { name: "data_collection", value } })}
              />
            </Col>
          </Form>
        </Col>
      )}
    </Row>
  )
}

export default ProgramMatrixTabs
